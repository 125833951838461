/* eslint-disable */
import { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";

import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import images
import { assetImages } from "constants";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//import options form json file
import SelectOption from "data/SelectOptions.json";
/* ------- import currency ----------- */
import currency from "data/Prod/Currency.json";
import CustomNotification from "components/common/CustomNotification";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import file download
import downloadFileHandler from "helpers/DownloadFile";
import UserProfileTabBreadCrumb from "../BreadCrumb/UserProfileTabBreadCrumb";

import {
  getUserBrowser,
  getPublicIP,
  getTimeStamp,
} from "helpers/AuthHelper/AuthHelper.js";

const PersonalInfoTab = (props) => {
  const { t } = useTranslation(); //for translation
  const params = useParams();

  const { setSuccess, setError, setNotificationString } =
    useContext(GlobalProvider);

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Type signature here..."),
  };
  /*---- CKEditor classic editor configuration end ----- */

  // custom style for category
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "#666666",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "5px",
      fontFamily: "sans-serif",
      border: "1px solid #D6D6D6",
      borderRadius: "8px",
      backgroundColor: "#fff",
      height: 50,
      boxShadow: "none",
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8E8E8E",
        padding: "5px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#505050" };
      return Object.assign(base, changes);
    },
  };

  //for tag/labels use state
  const [tagList, setTagList] = useState([]);

  //service list use state
  const [serviceList, setServiceList] = useState([]);

  //use state for label/tag value
  const [tagLabelValue, setTagLabelValue] = useState(null);

  //use state for service/category value
  const [serviceValue, setServiceValue] = useState(null);

  /* useState hook to change select data */
  const [languageSelectedValue, setLanguageSelectedValue] = useState(null);
  const [currencySelectedValue, setCurrencySelectedValue] = useState(null);

  const [userName, setUserName] = useState(""); //usestate for name
  const [surname, setSurname] = useState(""); //usestate for surname
  const [position, setPosition] = useState(""); //usestate for position
  const [cif, setCif] = useState(""); //usestate for cif
  const [companyName, setCompanyName] = useState(""); //usestate for company
  const [address, setAddress] = useState(""); //usestate for location
  const [email, setEmail] = useState(""); //usestate for email
  const [country, setCountry] = useState(""); // useState for country
  const [state, setState] = useState(""); // useState for state
  const [city, setCity] = useState(""); // useState for city
  const [zipcode, setZipcode] = useState(""); //usestate for zipcode
  const [latitude, setLatitude] = useState(""); //usestate for co lattitutde
  const [mapCoordinate, setMapCoordinate] = useState(""); //usestate for co ordinate
  const [longitude, setLongitude] = useState(""); //usestate for logitude
  const [labels, setLabels] = useState([]); //usestate for tag/labels
  const [phone, setPhone] = useState(""); //usestate for phone
  const [website, setWebsite] = useState(""); //usestate for webiste
  const [selectedLanguage, setSelectedLanguage] = useState(null); //usestate for language
  const [selectedCurrency, setSelectedCurrency] = useState(null); //usestate for currency
  const [services, setServices] = useState([]); //use state for selected services
  const [rank, setRank] = useState(0);
  const [costPerHour, setCostPerHour] = useState(1); //use state for cose per hour
  const [isUpdating, setIsUpdating] = useState(false); //for updating status
  const [emailExistence, setEmailExistence] = useState(false);
  const [mailSignature, setMailSignature] = useState("");
  const [uploadedUserFiles, setUploadedUserFiles] = useState([]); //uploaded user files

  //function for get list of all tags/labels
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_ALL_TAGS + `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get service/category list
  const getServiceList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_CATEGORIES +
        `?token=${token}&parent=services`;

      const response = await getData(requestUrl);

      if (response.status) {
        setServiceList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for language selection
  const languageSelectionHandler = (val) => {
    setLanguageSelectedValue(val);
    setSelectedLanguage(val.value);
  };

  const currencySelectionHandler = (val) => {
    setCurrencySelectedValue(val);
    setSelectedCurrency(val.value);
  };

  //function for select label/tag
  const labelSelectionHandler = (val) => {
    setTagLabelValue(val);
    const labelsArr = val.map((label) => {
      return label.value;
    });
    setLabels(labelsArr);
  };

  //function for select service/category
  const serviceSelectionHandler = (val) => {
    setServiceValue(val);
    const serviceArr = val.map((label) => {
      return label.value;
    });
    setServices(serviceArr);
  };

  /* function to fetch location from zip code */
  const getLocationFromZip = async (e) => {
    e.preventDefault();

    const url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}&address=ES&region=es&key=AIzaSyDt4dbtdHCHLj8grTzTVxpztvqUGDkvkaI`;

    console.log(url);

    if (zipcode !== "") {
      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.status === "OK") {
          const results = data.results;

          if (results.length > 0) {
            // console.log(`results`, JSON.stringify(results));

            results[0]?.address_components.map((item) => {
              // console.log(`item`, JSON.stringify(item));

              if (item.types[0] === "country") {
                setCountry(item?.long_name);
              }
              if (item.types[0] === "locality") {
                setCity(item?.long_name);
              }
              if (item.types[0] === "administrative_area_level_1") {
                setState(item?.long_name);
              }
              setLatitude(results[0]?.geometry?.location?.lat);
              setLongitude(results[0]?.geometry?.location?.lng);

              setMapCoordinate(
                `https://maps.google.com/maps?q=${results[0]?.geometry?.location?.lat},${results[0]?.geometry?.location?.lng}`
              );
            });
          } else {
            console.log("No results found.");
          }
        } else {
          console.log("Request failed with status:", data.status);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  //function for update user profile
  const updateUserHandler = async () => {
    const detectedBrowser = getUserBrowser();
    const userPublicIp = await getPublicIP();

    const { loginDate, loginTime, loginTimeStamp } = getTimeStamp();

    let userData = {
      name: userName,
      company: companyName,
      cif: cif,
      surname,
      position,
      address,
      city,
      state,
      country,
      zipcode,
      tags: labels,
      email,
      phone,
      website,
      language: selectedLanguage,
      currency: selectedCurrency,
      rank,
      services,
      mapcoordinate: mapCoordinate,
      latitude,
      longitude,
      costperhour: costPerHour,
      currency: selectedCurrency,
      mailsignature: mailSignature,
      browser: detectedBrowser,
      userip: userPublicIp,
    };

    // console.log(`userData`, userData);

    /* ----------- to check whether email exist or not --------------- */
    // const responseEmailCheck = await fetch(
    //   `https://api.zerobounce.in/v2/validate?api_key=fee40a741bdf4cf191b78f7ebba5127b&email=${email}`
    // );
    // const data = await responseEmailCheck.json();

    // console.log('data', JSON.stringify(data));

    // if (data.status === 'valid') {
    try {
      setIsUpdating(true);
      let requestUrl =
        url.API_BASEURL +
        url.API_UPDATE_USER +
        `/${params.id}` +
        `?token=${token}`;
      // console.log("personal info tab url----->", requestUrl);
      const response = await putData(requestUrl, userData);
      // console.log("response personal info tab---->", response);
      if (response.status) {
        props.afterUpdatePasswordTab();
        setSuccess(true);
        setNotificationString(
          t("Personal information has been updated successfully")
        );
      }
      setIsUpdating(false);
    } catch (error) {
      console.error(error.message);
      setError(true);
      setNotificationString(t("Something went wrong, please try again later"));
    }
    // } else {
    //   setError(true);
    //   setNotificationString(t('Email does not exist'));
    // }
  };

  useEffect(() => {
    console.log("userdata============", props.userData);
    //fetching all tag data
    getAllTags();

    //fetching all service/category list
    getServiceList();
    //check condition where user data available or not
    if (props.userData) {
      setCompanyName(props.userData?.company);
      setCif(props.userData?.cif);
      setUserName(props.userData?.name);
      setSurname(props.userData?.surname);
      setPosition(props.userData?.position);
      setAddress(props.userData?.address);
      setZipcode(props.userData?.zipcode);
      setEmail(props.userData?.email);
      setPhone(props.userData?.phone);
      setWebsite(props.userData?.website);
      setRank(props.userData?.rank);
      setCostPerHour(props.userData?.costperhour);
      setCountry(props.userData?.country);
      setCity(props.userData?.city);
      setMapCoordinate(props.userData.mapcoordinate);
      setLatitude(props.userData.latitude);
      setLongitude(props.userData.longitude);
      setState(props.userData.state);
      /*------ assign language start ------*/
      if (props.userData.language == "en") {
        let langData = {
          label: "English",
          value: "en",
        };
        languageSelectionHandler(langData); //set language value
      } else {
        let langData = {
          label: "Spanish",
          value: "es",
        };
        languageSelectionHandler(langData); //set languae value
      }
      /*------ assign language end ------*/

      /*------ assign labels start -------*/
      if (props.userData.labels) {
        labelSelectionHandler(props.userData.labels);
      }
      /*------ assign labels end -------*/

      /*------ assign services start -------*/
      if (props.userData.services) {
        serviceSelectionHandler(props.userData.services);
      }
      /*------ assign services end -------*/

      /*-------- assign currency start ---------*/
      if (props.userData.currency) {
        props?.userData?.currency == "usd"
          ? currencySelectionHandler({
              label: "Dollar",
              value: "usd",
              shortlabel: "$",
            })
          : currencySelectionHandler({
              label: "Euro",
              value: "euro",
              shortlabel: "€",
            });
      }
      /*-------- assign currency end ---------*/

      /*------- assign uploads start ---------*/
      if (props.userData.uploads) {
        setUploadedUserFiles(
          props.userData.uploads.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );
      }
      /*------- assign uploads end ---------*/
      //assign mail signature
      setMailSignature(
        props.userData?.mailsignature ? props.userData?.mailsignature : ""
      );
    }
  }, [props.userData]);

  return (
    <div className="profile_tabInnr">
      {/* ----- breadcrumb section start ------ */}
      <UserProfileTabBreadCrumb
        moduleName="Personal info"
        userData={props?.userData}
      />
      {/* ----- breadcrumb section end ------ */}

      {/* ----- user name and image section start ------ */}
      {props.userData ? (
        <div className="personel_profile_area">
          <div className="prsnl_profile_pic">
            {/* ------ user image area start ------ */}
            <figure>
              <img
                src={
                  props.userData?.photoimage
                    ? url.SERVER_URL + props.userData?.photoimage?.path
                    : assetImages.defaultUser
                }
                alt=""
              />
            </figure>
            {/* ------ user image area end ------ */}

            {/* ------- image upload section start ------- */}
            {/* <div className="upload_camera">
              <a href="#" className="upload_files">
                <input type="file" className="upload_btn" />
                <span className="material-icons-outlined">photo_camera</span>
              </a>
              <Link to="#" className="upload_delete">
                <span className="material-icons-outlined">delete</span>
              </Link>
            </div> */}
            {/* ------- image upload section end ------- */}
          </div>

          <div className="personl_profiledetails">
            <div className="profile_owner_outer">
              <div className="profile_owner_text">
                {/* ------ user name and surname ------ */}
                <h2>
                  {props.userData.name} {props.userData.surname}
                </h2>
                {/* ------ user postion ------ */}
                <h5>{props.userData.position}</h5>
                {/* <div className="edit_input input_fill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type name"
                  />
                </div> */}
              </div>
              {/* <div className="profile_owner_editbtn">
                <Link to="#" className="edit_pencil">
                  <span className="material-icons-outlined">edit</span>
                </Link>
                <button className="edit_send">
                  <span className="material-icons-outlined">send</span>
                </button>
              </div> */}
            </div>
            {/* <div className="profile_social">
              <ul className="list_stye_none">
                <li>
                  <Link to="#">
                    <img src={assetImages.faceboof} alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src={assetImages.gmail} alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src={assetImages.linkedin} alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src={assetImages.pinterest} alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="assets/img/svg/link.svg" alt="" />
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      ) : null}
      {/* ----- user name and image section end ------ */}

      <div className="profile_graybx_Outer">
        <div className="profile_graybx_item">
          <div className="profile_fillOuter">
            {/* ------ user manager section start ------ */}
            {props.userData ? (
              <div>
                {props.userData.manager ? (
                  <div className="profile_fill_row">
                    <label>{t("Manager")}</label>
                    <div className="input_fill">
                      <div className="usr_outer">
                        {/* ------- manager image section start ------- */}
                        <div className="usr_pic">
                          <img
                            src={
                              props.userData?.manager?.photoimage
                                ? url.SERVER_URL +
                                  props.userData?.manager?.photoimage?.path
                                : assetImages.defaultUser
                            }
                            alt=""
                          />
                        </div>
                        {/* ------- manager image section end ------- */}

                        {/* ------- manager name and surname section start ------- */}
                        {props.userData.manager ? (
                          <div className="usr_info">
                            <h5>
                              {props.userData.manager.name}{" "}
                              {props.userData.manager.surname}
                            </h5>
                          </div>
                        ) : null}
                        {/* ------- manager name and surname section end ------- */}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            {/* ------ user manager section end ------ */}

            {/* ------- user name and surname section start ------- */}
            <div className="profile_fill_row gap-5">
              {/* ----- name ----- */}
              <div className="w-50 d-flex align-items-center">
                <label>{t("Name")}</label>
                <div className="input_fill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("name")}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
              </div>
              
               {/* ----- surname ----- */}
              <div className="w-50 d-flex align-items-center">
                <label>{t("Surname")}</label>
                <div className="input_fill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("surname")}
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                  />
                </div>
              </div>

             
            </div>
            {/* ------- user name and surname section end ------- */}

            {/* ------- user email section start ------- */}
            <div className="profile_fill_row">
              <label>{t("Email")}</label>
              <div className="input_fill">
                <input
                  type="email"
                  value={email}
                  readOnly
                  onChange={(e) => {
                    setEmail(e.target.value), setEmailExistence(false);
                  }}
                  className="form-control"
                  placeholder={t("Enter email")}
                />
              </div>
            </div>
            {/* Email existence warning */}
            {emailExistence && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Email does not exist")}!</span>
                </p>
              </div>
            )}
            {/* ------- user email section end ------- */}

            {/* ------- user position section start ------- */}
            <div className="profile_fill_row">
              <label>{t("Position")}</label>
              <div className="input_fill">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Enter position")}
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                />
              </div>
            </div>
            {/* ------- user position section end ------- */}

            {/* ------- user compnay section start ------- */}
            <div className="profile_fill_row">
              <label>{t("CIF")}</label>
              <div className="input_fill">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Enter CIF")}
                  value={cif}
                  onChange={(e) => setCif(e.target.value)}
                />
              </div>
            </div>
            {/* ------- user compnay section end ------- */}

            {/* ------- user address section start ------- */}
            <div className="profile_fill_row">
              <label>{t("Address")}</label>
              <div className="input_fill">
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="form-control"
                  placeholder={t("Enter address")}
                />
              </div>
            </div>
            {/* ------- user address section end ------- */}

            {/* ------- user zipcode section start ------- */}
            <div className="profile_fill_row">
              <label>{t("Zip Code")}</label>
              <div className="input_fill">
                <input
                  type="text"
                  value={zipcode}
                  onChange={(e) => setZipcode(e.target.value)}
                  className="form-control"
                  placeholder={t("Enter")}
                />
              </div>
              <button
                className="btn btn-sm btn-primary ms-2"
                onClick={getLocationFromZip}
                type="button"
              >
                {t("Fetch location by zipcode")}
              </button>
            </div>
            {/* ------- user zipcode section end ------- */}

            {/* ------- user city section start ------- */}
            <div className="profile_fill_row">
              <label>{t("City")}</label>
              <div className="input_fill">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Enter City")}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            </div>
            {/* ------- user city section end ------- */}

            {/* ------- user state section start ------- */}
            <div className="profile_fill_row">
              <label>{t("State")}</label>
              <div className="input_fill">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Enter State")}
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
            </div>
            {/* ------- user state section end ------- */}

            {/* ------- user country section start ------- */}
            <div className="profile_fill_row">
              <label>{t("Country")}</label>
              <div className="input_fill">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Enter Country")}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
            </div>
            {/* ------- user country section end ------- */}

            {/* ------- user label/tags section start ------- */}
            {/* <div className='profile_fill_row'>
              <label>{t('labels')}</label>
              <div className='input_fill'>
                <Select
                  className='nosearch_select'
                  isMulti
                  options={tagList}
                  value={tagLabelValue}
                  onChange={val => labelSelectionHandler(val)}
                  styles={customSelectStyle}
                />
              </div>
            </div> */}
            {/* ------- user label/tags section end ------- */}

            {/* ------- user phone section start ------- */}
            <div className="profile_fill_row">
              <label>{t("Phone")}</label>
              <div className="input_fill">
                <input
                  type="number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="form-control"
                  placeholder="+ 41 78920-89231"
                />
              </div>
            </div>
            {/* ------- user phone section end ------- */}

            {/* -------- latitude and longitude section start ---------  */}
            <div className="profile_fill_row">
              {/* -------- latitude section start ---------  */}
              <label>{t("Latitude")}</label>
              <div className="input_fill">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Latitude")}
                  value={latitude}
                  onChange={(e) => setLatitude(e.target.value)}
                />
              </div>
              {/* -------- latitude section end ---------  */}

              {/* ------- currency section start ------- */}
              <label className="ms-3">{t("Longitude")}</label>
              <div className="input_fill">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Longitude")}
                  value={longitude}
                  onChange={(e) => setLongitude(e.target.value)}
                />
              </div>
              {/* ------- currency section end ------- */}
            </div>
            {/* -------- latitude and longitude section end ---------  */}

            {/* -------- map co ordinate section start ------- */}
            <div className="profile_fill_row">
              <label>{t("Map Coordinate")}</label>
              <div className="input_fill">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Enter GPS Position")}
                  value={mapCoordinate}
                  onChange={(e) => setMapCoordinate(e.target.value)}
                />
              </div>
              <button
                className="btn btn-sm btn-primary ms-1 my-1"
                onClick={() => window.open(mapCoordinate, "_blank")}
                type="button"
              >
                {t("Go to maps")}
              </button>
            </div>
            {/* -------- map co ordinate section end ------- */}

            {/* ------- user website section start ------- */}
            <div className="profile_fill_row">
              <label>{t("Website")}</label>
              <div className="input_fill">
                <input
                  type="text"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  className="form-control"
                  placeholder={t("Enter URL")}
                />
              </div>
            </div>
            {/* ------- user website section end ------- */}

            {/* ------- user language section start ------- */}
            <div className="profile_fill_row">
              <label>{t("Language")}</label>
              <div className="input_fill">
                <Select
                  className="nosearch_select"
                  options={SelectOption.languages}
                  value={languageSelectedValue}
                  onChange={(val) => {
                    languageSelectionHandler(val);
                  }}
                  styles={customSelectStyle}
                />
              </div>
            </div>
            {/* ------- user language section start ------- */}

            {/* ------- user services section start ------- */}
            <div className="profile_fill_row">
              <label>{t("Services")}</label>
              <div className="input_fill">
                <Select
                  isMulti
                  styles={customSelectStyle}
                  options={serviceList}
                  value={serviceValue}
                  onChange={(val) => {
                    serviceSelectionHandler(val);
                  }}
                />
              </div>
            </div>
            {/* ------- user services section end ------- */}

            {/* ------- cost per hour and currency row start ------- */}
            <div className="profile_fill_row">
              {/* ------- cost per hour section start ------- */}
              <label>{t("Cost per hour")}</label>
              <div className="input_fill">
                <input
                  type="number"
                  value={costPerHour}
                  onChange={(e) => setCostPerHour(e.target.value)}
                  className="form-control"
                  placeholder={t("Enter cost per hour")}
                />
              </div>
              {/* ------- cost per hour section end ------- */}

              {/* ------- currency section start ------- */}
              <label className="ms-3">{t("Currency")}</label>
              <div className="input_fill">
                <Select
                  className="nosearch_select"
                  options={currency}
                  value={currencySelectedValue}
                  onChange={(val) => {
                    currencySelectionHandler(val);
                  }}
                  styles={customSelectStyle}
                />
              </div>
              {/* ------- currency section end ------- */}
            </div>
            {/* ------- cost per hour and currency row end ------- */}

            {/* ------- user rank section start ------- */}
            {
              (userInfo.role.name = "admin" && (
                <div className="profile_fill_row">
                  <label>{t("Rank")}</label>
                  <div className="input_fill">
                    <input
                      type="number"
                      value={rank}
                      onChange={(e) => setRank(e.target.value)}
                      className="form-control"
                      placeholder={t("Enter Rank")}
                    />
                  </div>
                </div>
              ))
            }
            {/* ------- user rank section end ------- */}

            {/* ----- mail signature start ----- */}
            <div className="profile_fill_row">
              <label>{t("Mail Signature")}</label>
              <div className="input_fill">
                <CKEditor
                  className="form-control"
                  editor={ClassicEditor}
                  config={editorConfig}
                  data={mailSignature}
                  onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        "height",
                        "200px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                  onChange={(event, editor) => {
                    let editorContent = editor.getData();
                    setMailSignature(editorContent);
                  }}
                />
              </div>
            </div>
            {/* ----- mail signature end ----- */}

            {/* ------ uploaded documents section start ------ */}
            <div className="profile_fill_row">
              <label>{t("Documents")}</label>
              <div className="input_fill">
                {uploadedUserFiles.length > 0 && (
                  <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                    {uploadedUserFiles.map((file, index) => {
                      return (
                        <li
                          className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                          key={index}
                        >
                          <p className="fw-medium">{file.name}</p>
                          <Link
                            to="#"
                            onClick={() => downloadFileHandler(file)}
                          >
                            <span className="d-block material-symbols-outlined">
                              download
                            </span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
            {/* ------ uploaded documents section end ------ */}
          </div>

          {/* ------ update and cacel button section start ------ */}
          <div className="prfile_btnOuter">
            <div className="profile_rightBtn">
              {/* ------- update button ------ */}
              <button
                className="btn btn-primary"
                onClick={updateUserHandler}
                style={{ cursor: isUpdating ? "not-allowed" : "pointer" }}
                disabled={isUpdating ? true : false}
              >
                {t("Update")}
                {isUpdating && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
              {/* ------ cancel button ------ */}
              <button className="btn btn-secondary">{t("Cancel")}</button>
            </div>
          </div>
          {/* ------ update and cacel button section end ------ */}
        </div>
      </div>
      {/* custom notification component */}
      <CustomNotification />
    </div>
  );
};

export default PersonalInfoTab;
