/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { postData, putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

const AddNewHolidayPopup = () => {
  const token = localStorage.getItem("token"); // token

  const { t } = useTranslation(); //for translation

  const {
    setReloadHolidayCalendar,
    editHolidayCalendarData,
    setEditHolidayCalendarData,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  //get today value
  const today = new Date().toISOString().split("T")[0];

  //function for get current time and set by defualt
  // const getCurrentTime = () => {
  //   const now = new Date();
  //   const hours = String(now.getHours()).padStart(2, '0');
  //   const minutes = String(now.getMinutes()).padStart(2, '0');

  //   const timenow = `${hours}:${minutes}`;

  //   return timenow;
  // };

  /* useState hook for input fields */
  const [holidayInputs, setHolidayInputs] = useState({
    name: "",
    date: today,
    enddate: today,
    description: "",
  });

  const [dateCompareWarning, setDateCompareWarning] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  /* function to delete schedule */
  const deleteScheduleHandler = async () => {
    setIsDeleting(true);

    try {
      let requestUrl = url.API_BASEURL + 
                        url.API_DELETE_HOLIDAY_SCHEDULE + 
                        `/${editHolidayCalendarData.id}` + `?token=${token}`;

      const response = await postData(requestUrl);

      console.log(`response submiting holiday data ========>>`, response);

      setIsDeleting(false);

      if (response.status) {
        setSuccess(true);
        setNotificationString(t("Holiday deleted successfully"));
        setReloadHolidayCalendar(true);

        bootstrap.Offcanvas.getInstance(
          document.querySelector("#addHolidaySchedulePopup")
        ).hide();
      } 

    } catch (error) {

      console.error(error.message);

      setError(true);

    }
  
  }



  /* function to add calender event */
  const addHolidayScheduleHandler = async (e) => {
    e.preventDefault();

    let scheduleData = {
      name: holidayInputs.name,
      date: holidayInputs.date,
      description: holidayInputs.description,
      enddate: holidayInputs.enddate,
    };

    console.log(`Schedule Data===========`, scheduleData);
    if (scheduleData.date <= scheduleData.enddate) {
      try {
        let requestUrl =
          url.API_BASEURL +
          url.API_ADD_NEW_HOLIDAY_SCHEDULE +
          `?token=${token}`;

        const response = await postData(requestUrl, scheduleData);

        console.log(`response submiting holiday data ========>>`, response);

        if (response.status) {
          resetHandler();
          setSuccess(true);
          setNotificationString(t("Holiday added successfully"));
          setReloadHolidayCalendar(true);
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString(
          t("Something went wrong, please try again later")
        );
      }
      bootstrap.Offcanvas.getInstance(
        document.querySelector("#addHolidaySchedulePopup")
      ).hide();
    } else {
      setDateCompareWarning(true);
    }
  };

  /* function to udpate calendar event */
  const updateHolidayScheduleHandler = async (e) => {
    e.preventDefault();

    let scheduleData = {
      name: holidayInputs.name,
      date: holidayInputs.date,
      description: holidayInputs.description,
      enddate: holidayInputs.enddate,
    };

    console.log(`Schedule Data===========`, scheduleData);

    if (scheduleData.date <= scheduleData.enddate) {
      console.log("trugger if");
      try {
        let requestUrl =
          url.API_BASEURL +
          url.API_UPDATE_HOLIDAY_SCHEDULE +
          `/${editHolidayCalendarData.id}` +
          `?token=${token}`;

        const response = await putData(requestUrl, scheduleData);

        console.log(`response submiting holiday data ========>>`, response);

        if (response.status) {
          resetHandler();
          setSuccess(true);
          setNotificationString(t("Holiday edited successfully"));
          setReloadHolidayCalendar(true);
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString(
          t("Something went wrong, please try again later")
        );
      }
      bootstrap.Offcanvas.getInstance(
        document.querySelector("#addHolidaySchedulePopup")
      ).hide();
    } else {
      setDateCompareWarning(true);
    }
  };

  /* reset handler */
  const resetHandler = () => {
    setHolidayInputs({
      name: "",
      date: today,
      enddate: today,
      description: "",
    });
    setEditHolidayCalendarData(null);
    setDateCompareWarning(false);
  };

  /* hook to ebit events */
  useEffect(() => {
    if (editHolidayCalendarData) {
      console.log(
        `editHolidayCalendarData============>>`,
        editHolidayCalendarData
      );
      if (editHolidayCalendarData.title) {
        setHolidayInputs((prevState) => ({
          ...prevState,
          name: editHolidayCalendarData.title,
        }));
      }
      if (editHolidayCalendarData.date) {
        setHolidayInputs((prevState) => ({
          ...prevState,
          date: editHolidayCalendarData.date,
        }));
        setHolidayInputs((prevState) => ({
          ...prevState,
          enddate: editHolidayCalendarData.date,
        }));
      }
      if (editHolidayCalendarData.description) {
        setHolidayInputs((prevState) => ({
          ...prevState,
          description: editHolidayCalendarData.description,
        }));
      }
    }
  }, [editHolidayCalendarData]);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      id="addHolidaySchedulePopup"
    >
      <div className="offcanvas-header align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">
            {editHolidayCalendarData
              ? t("Update Holiday Event")
              : t("Add Holiday Event")}
          </span>
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <form
          onSubmit={
            editHolidayCalendarData
              ? updateHolidayScheduleHandler
              : addHolidayScheduleHandler
          }
        >
          <div className="calendar_data_item">
            <div className="form-group mb-4">
              <label>{t("Title")} *</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Title")}
                value={holidayInputs.name}
                onChange={(val) => {
                  setHolidayInputs((prevState) => ({
                    ...prevState,
                    name: val.target.value,
                  }));
                }}
              />
            </div>

            {/* ========== date section start ============ */}
            <div className="form-group mb-4">
              <div className="row">
                {/* -------- start date section start --------- */}
                <div className="col-lg-6">
                  <label htmlFor="date">{t("Start Date")} *</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="DD/MM/YYYY"
                    value={holidayInputs.date}
                    onChange={(val) => {
                      setHolidayInputs((prevState) => ({
                        ...prevState,
                        date: val.target.value,
                      }));
                      setDateCompareWarning(false);
                    }}
                  />
                </div>
                {/* -------- start date section end --------- */}

                {/* -------- end date section start --------- */}
                <div className="col-lg-6">
                  <label htmlFor="date">{t("End Date")}</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="DD/MM/YYYY"
                    value={holidayInputs.enddate}
                    onChange={(val) => {
                      setHolidayInputs((prevState) => ({
                        ...prevState,
                        enddate: val.target.value,
                      }));
                      setDateCompareWarning(false);
                    }}
                  />
                </div>
                {/* -------- end date section end --------- */}
              </div>

              {dateCompareWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>
                      {t("End date should be greater than start date")}!
                    </span>
                  </p>
                </div>
              )}
            </div>
            {/* ========== date section end ============ */}

            {/* =============== description section starts ============= */}
            <div className="form-group mb-4">
              <label htmlFor="educatorComments">{t("Description")}</label>
              <textarea
                name="educatorComments"
                id="educatorComments"
                cols="30"
                rows="4"
                className="form-control"
                placeholder={t("Enter Description")}
                value={holidayInputs.description}
                onChange={(val) => {
                  setHolidayInputs((prevState) => ({
                    ...prevState,
                    description: val.target.value,
                  }));
                }}
              ></textarea>
            </div>
            {/* =============== description section ends ============= */}
          </div>

          <div className="action d-flex align-items-center gap-2">
            <button type="submit" className="btn btn-primary">
              {editHolidayCalendarData ? t("Update") : t("Save")}
            </button>

            {editHolidayCalendarData?.id && (
              <button
                className="btn btn-danger"
                onClick={deleteScheduleHandler}
                disabled={isDeleting ? true : false}
                style={{ cursor: isDeleting ? "not-allowed" : "pointer" }}
              >
                {t("Delete")}{" "}
                {isDeleting && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            )}


          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewHolidayPopup;
