/* eslint-disable */
import { useState, createContext } from "react";

export const GlobalProvider = createContext();

const GlobalContext = (props) => {
  /*------- userlist requirment start -------*/
  const [isUserListLoading, setIsUserListLoading] = useState(false);
  const [showUserList, setShowUserList] = useState(false); //for display userlist
  const [editUserData, setEditUserData] = useState(null); // set user data for edit purpose
  const [copyUserData, setCopyUserData] = useState(null); // set user data for copy purpose
  const [showUserNameColumn, setShowUserNameColumn] = useState(true); // display name column in kendo react
  const [showUserSurnameColumn, setShowUserSurnameColumn] = useState(true); // display surname column in kendo react
  const [showUserRoleColumn, setShowUserRoleColumn] = useState(true); // display role column in kendo react
  const [showUserTagColumn, setShowUserTagColumn] = useState(true); // display tag column in kendo react
  const [showCompanyColumn, setShowCompanyColumn] = useState(true); // display company column in kendo react
  const [showUserEmailColumn, setShowUserEmailColumn] = useState(true); // display email column in kendo react
  const [showUserPhoneColumn, setShowUserPhoneColumn] = useState(true); // display phone column in kendo react
  const [showUserCityColumn, setShowUserCityColumn] = useState(true); // display city column in kendo react
  const [showUserStateColumn, setShowUserStateColumn] = useState(true); // display state column in kendo react
  const [showUserCountryColumn, setShowUserCountryColumn] = useState(true); // display country column in kendo react
  const [userFormReset, setUserFormReset] = useState(false);
  const [searchUserRoles, setSearchUserRoles] = useState([]);
  const [searchUserTags, setSearchUserTags] = useState([]);
  const [searchUserStatus, setSearchUserStatus] = useState([]);
  const [searchUserZipcodes, setSearchUserZipcodes] = useState([]);
  const [searchUserCityList, setSearchUserCityList] = useState([]);
  const [searchUserStateList, setSearchUserStateList] = useState([]);
  const [searchUserCountryList, setSearchUserCountryList] = useState([]);
  const [searchUserIsCompany, setSearchUserIsCompany] = useState(false);
  /*------- userlist requirment end -------*/

  /*------ material kit requirment start ------*/
  const [reloadMaterialList, setReloadMaterialList] = useState(false); //for reload material list
  const [editMaterialData, setEditMaterialData] = useState(null); //for edit material data
  const [showMaterialKitNameColumn, setShowMaterialKitNameColumn] =
    useState(true); //for show material kit name column
  const [showMaterialKitSlugColumn, setShowMaterialKitSlugColumn] =
    useState(true); //for show material kit slug column
  const [showMaterialKitSubjectColumn, setShowMaterialKitSubjectColumn] =
    useState(true); //for show material kit subject column
  const [showMaterialKitLabelColumn, setShowMaterialKitLabelColumn] =
    useState(true); //for show material kit label column
  const [showMaterialKitDate, setShowMaterialKitDate] = useState(true); //for show material kit date column
  /*------ material kit requirment end ------*/

  /* -------- course requirement start ---------- */
  const [reloadCourseList, setReloadCourseList] = useState(false);
  const [editCourseData, setEditCourseData] = useState(null);
  /* -------- course requirement end ---------- */

  /*------- activity requirement start --------*/
  const [reloadActivityList, setReloadActivityList] = useState(false);
  const [showActivityNameColumn, setShowActivityNameColumn] = useState(true);
  const [showActivityCourseColumn, setShowActivityCourseColumn] =
    useState(true);
  const [showActivityCodeColumn, setShowActivityCodeColumn] = useState(true);
  const [showActivityStartDateColumn, setShowActivityStartDateColumn] =
    useState(true);
  const [showActivityEndDateColumn, setShowActivityEndDateColumn] =
    useState(true);
  const [showActivityAudienceColumn, setShowActivityAudienceColumn] =
    useState(true);
  const [showActivityObservationColumn, setShowActivityObservationColumn] =
    useState(true);
  const [showActivityDurationColumn, setShowActivityDurationColumn] =
    useState(true);
  const [
    showActivityPreparationTimeColumn,
    setshowActivityPreparationTimeColumn,
  ] = useState(true);
  const [showActivityMountingTimeColumn, setshowActivityMountingTimeColumn] =
    useState(true);
  const [showActivityDismantleTimeColumn, setshowActivityDismantleTimeColumn] =
    useState(true);
  const [showAmbActivityCodeColumn, setShowAmbActivityCodeColumn] = useState(true);
  const [editActivityData, setEditActivityData] = useState(null);
  const [activityFormReset, setActivityFormReset] = useState(false);
  const [searchActivityName, setSearchActivityName] = useState("");
  const [searchActivityCode, setsearchActivityCode] = useState("");
  const [searchActivityCourse, setSearchActivityCourse] = useState([]);
  const [searchActivityLot, setSearchActivityLot] = useState([]);
  /*------- activity requirement end --------*/

  /* ============= Reservation requirement start ================== */
  const [isReserveListLoading, setIsReserveListLoading] = useState(false);
  const [reloadReservationList, setReloadReservationList] = useState(false);
  const [reloadRefresh, setReloadRefresh] = useState(false);
  const [editReserveData, setEditReserveData] = useState(null);
  const [changeReserveEducatorData, setChangeReserveEducatorData] =
    useState(null);
  const [allowSelectEducator, setAllowSelectEducator] = useState(true);
  const [reserveIdNumber, setReserveIdNumber] = useState(null);
  const [resetReserveSaveForm, setResetReserveSaveForm] = useState(false);
  const [reservePersonData, setReservePersonData] = useState(null);
  const [reserveSurveyData, setReserveSurveyData] = useState(null);
  const [clientFeedbackEducatorData, setClientFeedbackEducatorData] =
    useState(null);
  const [reservationEmailHistoryData, setReservationEmailHistoryData] =
    useState(null);
  const [customReserveId, setCustomReserveId] = useState(null);
  const [copyReserveData, setCopyReserveData] = useState(null);
  const [searchReserveDate, setSearchReserveDate] = useState("");
  const [searchReserveIdList, setSearchReserveIdList] = useState([]);
  const [searchReserveAmbIdList, setSearchReserveAmbIdList] = useState([]);
  const [searchReserveActivity, setSearchReserveActivity] = useState([]);
  const [searchReserveLabels, setSearchReserveLabels] = useState([]);
  const [searchReserveBenificiaries, setSearchReserveBenificiaries] = useState(
    []
  );
  const [searchReserveClients, setSearchReserveClients] = useState([]);
  const [searchReserveExcludeClients, setSearchReserveExcludeClients] =
    useState([]);
  const [searchReserveEducators, setSearchReserveEducators] = useState([]);
  const [searchReserveStatus, setSearchReserveStatus] = useState([]);
  const [viewMail, setViewMail] = useState(false);
  const [isConsecutiveReserveFilter, setisConsecutiveReserveFilter] =
    useState(false);
  const [isTemplateReserveFilter, setIsTemplateReserveFilter] = useState(false);
  const [unsentMailReserveFilter, setUnsentMailReserveFilter] = useState(false);
  const [isDefaultReserveFilter, setIsDefaultReserveFilter] = useState(false);
  const [addMoreConsecutiveReserveData, setaddMoreConsecutiveReserveData] =
    useState(null);
  const [reloadReserveTemplateList, setReloadReserveTemplateList] =
    useState(false);
  const [userFormRole, setUserFormRole] = useState("");
  const [reloadReserveFormOptions, setReloadReserveFormOptions] =
    useState(false);
  const [newAddedClientResult, setNewAddedClientResult] = useState([]);
  const [newAddedBeneficiaryResult, setNewAddedBeneficiaryResult] = useState(
    []
  );
  const [newAddedObserverResult, setNewAddedObserverResult] = useState([]);
  const [newAddedParticipantResult, setNewAddedParticipantResult] = useState(
    []
  );
  const [newAddedResponsibleResult, setNewAddedResponsibleResult] = useState(
    []
  );
  /* ============= Reservation requirement end ================== */

  /* ============== label requirement start ============= */
  const [reloadLabelList, setReloadLabelList] = useState(false);
  const [resetAddUpdateLabelPopup, setResetAddUpdateLabelPopup] =
    useState(false);
  const [editLabelData, setEditLabelData] = useState(null);
  /* ============== label requirement end ============= */

  /* ============ calendar requirement start =============  */
  const [reloadCalendarEvents, setReloadCalendarEvents] = useState(false);
  const [editCalendarData, setEditCalendarData] = useState([]);
  /* ============ calendar requirement end =============  */

  /* ============ Holiday requirement start =============  */
  const [reloadHolidayCalendar, setReloadHolidayCalendar] = useState(false);
  const [editHolidayCalendarData, setEditHolidayCalendarData] = useState(null);
  /* ============ Holiday requirement end =============  */

  /* ============ question requirement start =============  */
  const [editQuestionData, setEditQuestionData] = useState(null);
  const [reloadQuestionList, setReloadQuestionList] = useState(false);
  const [resetQuestionPopup, setResetQuestionPopup] = useState(false);
  /* ============ question requirement end =============  */

  /* ============ survey requirement start =============  */
  const [reloadSurveyList, setReloadSurveyList] = useState(false);
  const [editSurveyData, setEditSurveyData] = useState(null);
  /* ============ survey requirement end =============  */

  /* ============== mail template requirement start ============= */
  const [reloadMailTemplateList, setReloadMailTemplateList] = useState(false);
  const [editMailTemplateData, setEditMailTemplateData] = useState(null);
  const [viewMailTemplateData, setViewMailTemplateData] = useState(null);
  const [copyMailTemplateId, setCopyMailTemplateId] = useState(null);
  const [searchMailTemplateDate, setSearchMailTemplateDate] = useState("");
  const [searchMailTemplateName, setSearchMailTemplateName] = useState("");
  const [searchMailTemplateSubject, setSearchMailTemplateSubject] =
    useState("");
  const [searchMailTemplateLabels, setSearchMailTemplateLabels] = useState([]);
  const [searchMailTemplateIsPublic, setSearchMailTemplateIsPublic] =
    useState(false);
  const [showMailTemplateNameColumn, setShowMailTemplateNameColumn] =
    useState(true);
  const [showMailTemplateSlugColumn, setShowMailTemplateSlugColumn] =
    useState(true);
  const [showMailTemplateSubjectColumn, setShowMailTemplateSubjectColumn] =
    useState(true);
  const [showMailTemplateLabelColumn, setShowMailTemplateLabelColumn] =
    useState(true);
  const [showMailTemplateDateColumn, setShowMailTemplateDateColumn] =
    useState(true);
  /* ============== mail template requirement  end ============= */

  /* ============== message / conversation requirement start ============= */
  const [reloadConversationList, setReloadConversationList] = useState(false);
  const [viewConversationData, setViewConversationData] = useState(null);
  const [searchMailReciever, setSearchMailReciever] = useState(null);
  const [searchMailSender, setSearchMailSender] = useState(null);
  const [searchMailSubject, setSearchMailSubject] = useState("");
  const [searchMailDate, setSearchMailDate] = useState("");
  const [searchMailTemplateSelect, setSearchMailTemplateSelect] = useState([]);
  const [searchMailLabelSelect, setSearchMailLabelSelect] = useState([]);
  /* ============== message requirement end ============= */

  /* ============== sales order requirement start ============= */
  const [salesOrderData, setSalesOrderData] = useState(null);
  const [reloadSalesOrderList, setReloadSalesOrderList] = useState(false);

  const [searchSalesOrderDate, setSearchSalesOrderDate] = useState("");
  const [searchSalesOrderDateType, setSearchSalesOrderDateType] =
    useState(null);
  const [searchSalesOrderClients, setSearchSalesOrderClients] = useState([]);
  const [searchSalesOrderBudgetStatus, setSearchSalesOrderBudgetStatus] =
    useState([]);
  const [searchSalesOrderDiscount, setSearchSalesOrderDiscount] = useState(0);
  const [showSalesOrderInvoiceIdColumn, setShowSalesOrderInvoiceIdColumn] =
    useState(true);
  const [showSalesOrderClientColumn, setShowSalesOrderClientColumn] =
    useState(true);
  const [showSalesOrderInvoiceDateColumn, setShowSalesOrderInvoiceDateColumn] =
    useState(true);
  const [
    showSalesOrderBudgetStatusColumn,
    setShowSalesOrderBudgetStatusColumn,
  ] = useState(true);
  const [showSalesOrderDiscountColumn, setShowSalesOrderDiscountColumn] =
    useState(true);
  const [showSalesOrderTotalAmountColumn, setShowSalesOrderTotalAmountColumn] =
    useState(true);
  const [showSalesOrderCreatedDateColumn, setShowSalesOrderCreatedDateColumn] =
    useState(true);
  const [showSalesOrderPayblePriceColumn, setShowSalesOrderPayblePriceColumn] =
    useState(true);
  const [editSalesOrderItemData, setEditSalesOrderItemData] = useState(null);
  const [afterEditReload, setAfterEditReload] = useState(false);
  const [clientEmail, setclientEmail] = useState("");
  const [budgetToSalesOrderId, setBudgetToSalesOrderId] = useState(null);
  /* ============== sales order requirement end ============= */

  /*---------- concept requirement start ------------*/
  const [reloadConceptList, setReloadConceptList] = useState(false);
  const [resetAddUpdateConceptPopup, setResetAddUpdateConceptPopup] =
    useState(false);
  const [editConceptData, setEditConceptData] = useState(null);
  /*---------- concept requirement end ------------*/

  /* =============== notification requirement start =================== */
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [notificationString, setNotificationString] = useState("");
  /* =============== notification requirement end =================== */

  /* ---------------- feedback response requirement start --------------------- */
  const [reloadFeedbackResponse, setReloadFeedbackResponse] = useState(false);
  /* ---------------- feedback response requirement end --------------------- */

  /* ----------------- cost template requirement start ----------------------- */
  const [reloadCostList, setReloadCostList] = useState(false);
  const [editCostData, setEditCostData] = useState(null);
  const [searchCostDate, setSearchCostDate] = useState("");
  const [searchCostEducators, setSearchCostEducators] = useState([]);
  const [searchCostClients, setSearchCostClients] = useState([]);
  /* ----------------- cost template requirement end ----------------------- */

  /* ------ schedule material requirement start --------*/
  const [reloadScheduleMaterial, setReloadScheduleMaterial] = useState(false);
  const [editScheduleMaterialData, setEditScheduleMaterialData] =
    useState(null);
  /* ------ schedule material requirement end --------*/

  /*------- agenda requirement start -------*/
  const [agendaWaitning, setAgendaWaitning] = useState(false); //for waitng for get agenda
  const [reloadAgenda, setReloadAgenda] = useState(false);
  const [filterAgendaIncludeClientId, setfilterAgendaIncludeClientId] =
    useState(null);
  const [filterAgendaExcludeClientId, setfilterAgendaExcludeClientId] =
    useState(null);
  const [filterAgendaEducatorTags, setFilterAgendaEducatorTags] = useState([]);
  /*------- agenda requirement end -------*/

  /*------- RolePriviledge requirement start -------*/
  const [reloadRolePriviledgeList, setReloadRolePriviledgeList] =
    useState(false);
  const [rolePriviledgeData, setRolePriviledgeData] = useState(null);
  /*------- RolePriviledge requirement end -------*/

  /*------- project requirement end -------*/
  const [reloadProjectList, setreloadProjectList] = useState(false);
  const [editProjectId, seteditProjectId] = useState(null);
  const [filterDateRange, setFilterDateRange] = useState("");
  const [filterTitle, setFilterTitle] = useState("");
  const [filterTags, setFilterTags] = useState([]);
  /*------- project requirement end -------*/

  /*------- task requirement end -------*/
  const [reloadTaskList, setreloadTaskList] = useState(false);
  const [editTaskId, seteditTaskId] = useState(null);
  const [filterTaskDateRange, setfilterTaskDateRange] = useState("");
  const [filterTaskTitle, setfilterTaskTitle] = useState("");
  const [filterTaskProjects, setfilterTaskProjects] = useState([]);
  const [filterTaskUsers, setfilterTaskUsers] = useState([]);
  const [filterTaskStatus, setfilterTaskStatus] = useState([]);
  const [filterTaskPriorities, setfilterTaskPriorities] = useState([]);
  const [filterTaskLabels, setfilterTaskLabels] = useState([]);
  /*------- task requirement end -------*/

  /*------- employee log requirement end -------*/
  const [reloadEmployeeLogList, setReloadEmployeeLogList] = useState(false);
  const [filterEmployeeLogDates, setfilterEmployeeLogDates] = useState("");
  const [filterEmployeeLogUsers, setfilterEmployeeLogUsers] = useState([]);
  /*------- employee log requirement end -------*/

  /*------- actionlog requirement start -------*/
  const [reloadActionLogList, setreloadActionLogList] = useState(false);
  const [filterActionLogDates, setfilterActionLogDates] = useState("");
  /*------- actionlog requirement end -------*/

  /* ================= screen height =============================== */
  const [screenHeight, setScreenHeight] = useState(window.innerHeight - 170);

  return (
    <GlobalProvider.Provider
      value={{
        /*------- userlist requirment start -------*/
        isUserListLoading,
        setIsUserListLoading,
        showUserList,
        setShowUserList,
        editUserData,
        setEditUserData,
        copyUserData,
        setCopyUserData,
        showUserNameColumn,
        setShowUserNameColumn,
        showUserSurnameColumn,
        setShowUserSurnameColumn,
        showUserRoleColumn,
        setShowUserRoleColumn,
        showUserEmailColumn,
        setShowUserEmailColumn,
        showUserTagColumn,
        setShowUserTagColumn,
        showCompanyColumn,
        setShowCompanyColumn,
        showUserPhoneColumn,
        setShowUserPhoneColumn,
        showUserCityColumn,
        setShowUserCityColumn,
        showUserStateColumn,
        setShowUserStateColumn,
        showUserCountryColumn,
        setShowUserCountryColumn,
        userFormReset,
        setUserFormReset,
        searchUserRoles,
        setSearchUserRoles,
        searchUserTags,
        setSearchUserTags,
        searchUserStatus,
        setSearchUserStatus,
        searchUserZipcodes,
        setSearchUserZipcodes,
        searchUserCityList,
        setSearchUserCityList,
        searchUserStateList,
        setSearchUserStateList,
        searchUserCountryList,
        setSearchUserCountryList,
        searchUserIsCompany,
        setSearchUserIsCompany,
        /*------- userlist requirment end -------*/

        /*------- material kit requirment start -------*/
        reloadMaterialList,
        setReloadMaterialList,
        editMaterialData,
        setEditMaterialData,
        showMaterialKitNameColumn,
        setShowMaterialKitNameColumn,
        showMaterialKitSlugColumn,
        setShowMaterialKitSlugColumn,
        showMaterialKitSubjectColumn,
        setShowMaterialKitSubjectColumn,
        showMaterialKitLabelColumn,
        setShowMaterialKitLabelColumn,
        showMaterialKitDate,
        setShowMaterialKitDate,
        /*------- material kit requirment end -------*/

        /* -------- course requirement start ---------- */
        reloadCourseList,
        setReloadCourseList,
        editCourseData,
        setEditCourseData,
        /* -------- course requirement end ---------- */

        /*------- activity requirement start --------*/
        reloadActivityList,
        setReloadActivityList,
        showActivityNameColumn,
        setShowActivityNameColumn,
        showActivityCourseColumn,
        setShowActivityCourseColumn,
        showActivityCodeColumn,
        setShowActivityCodeColumn,
        showActivityStartDateColumn,
        setShowActivityStartDateColumn,
        showActivityEndDateColumn,
        setShowActivityEndDateColumn,
        showActivityAudienceColumn,
        setShowActivityAudienceColumn,
        showActivityObservationColumn,
        setShowActivityObservationColumn,

        showActivityDurationColumn,
        setShowActivityDurationColumn,

        showActivityPreparationTimeColumn,
        setshowActivityPreparationTimeColumn,
        showActivityMountingTimeColumn,
        setshowActivityMountingTimeColumn,
        showActivityDismantleTimeColumn,
        setshowActivityDismantleTimeColumn,
        showAmbActivityCodeColumn,
        setShowAmbActivityCodeColumn,

        editActivityData,
        setEditActivityData,
        activityFormReset,
        setActivityFormReset,
        searchActivityName,
        setSearchActivityName,
        searchActivityCode,
        setsearchActivityCode,
        searchActivityCourse,
        setSearchActivityCourse,
        searchActivityLot,
        setSearchActivityLot,
        /*------- activity requirement end --------*/

        /* ============= Reservation requirement start ================== */
        isReserveListLoading,
        setIsReserveListLoading,
        reloadReservationList,
        setReloadReservationList,
        reloadRefresh,
        setReloadRefresh,
        editReserveData,
        setEditReserveData,
        allowSelectEducator,
        setAllowSelectEducator,
        changeReserveEducatorData,
        setChangeReserveEducatorData,
        reserveIdNumber,
        setReserveIdNumber,
        resetReserveSaveForm,
        setResetReserveSaveForm,
        reservePersonData,
        setReservePersonData,
        reserveSurveyData,
        setReserveSurveyData,
        clientFeedbackEducatorData,
        setClientFeedbackEducatorData,
        reservationEmailHistoryData,
        setReservationEmailHistoryData,
        customReserveId,
        setCustomReserveId,
        copyReserveData,
        setCopyReserveData,
        searchReserveDate,
        setSearchReserveDate,
        searchReserveIdList,
        setSearchReserveIdList,
        searchReserveAmbIdList,
        setSearchReserveAmbIdList,
        searchReserveActivity,
        setSearchReserveActivity,
        searchReserveLabels,
        setSearchReserveLabels,
        searchReserveBenificiaries,
        setSearchReserveBenificiaries,
        searchReserveClients,
        setSearchReserveClients,
        searchReserveExcludeClients,
        setSearchReserveExcludeClients,
        searchReserveEducators,
        setSearchReserveEducators,
        searchReserveStatus,
        setSearchReserveStatus,
        viewMail,
        setViewMail,
        isConsecutiveReserveFilter,
        setisConsecutiveReserveFilter,
        addMoreConsecutiveReserveData,
        setaddMoreConsecutiveReserveData,
        isTemplateReserveFilter,
        setIsTemplateReserveFilter,
        unsentMailReserveFilter,
        setUnsentMailReserveFilter,
        isDefaultReserveFilter,
        setIsDefaultReserveFilter,
        reloadReserveTemplateList,
        setReloadReserveTemplateList,
        userFormRole,
        setUserFormRole,
        reloadReserveFormOptions,
        setReloadReserveFormOptions,
        newAddedClientResult,
        setNewAddedClientResult,
        newAddedBeneficiaryResult,
        setNewAddedBeneficiaryResult,
        newAddedObserverResult,
        setNewAddedObserverResult,
        newAddedParticipantResult,
        setNewAddedParticipantResult,
        newAddedResponsibleResult,
        setNewAddedResponsibleResult,
        /* ============= Reservation requirement end ================== */

        /* ============== label requirement start ============= */
        reloadLabelList,
        setReloadLabelList,
        editLabelData,
        setEditLabelData,
        resetAddUpdateLabelPopup,
        setResetAddUpdateLabelPopup,
        /* ============== label requirement end ============= */

        /* ============ calendar requirement start =============  */
        reloadCalendarEvents,
        setReloadCalendarEvents,
        editCalendarData,
        setEditCalendarData,
        /* ============ calendar requirement end =============  */

        /* ============ Holiday requirement start =============  */
        reloadHolidayCalendar,
        setReloadHolidayCalendar,
        editHolidayCalendarData,
        setEditHolidayCalendarData,
        /* ============ Holiday requirement end =============  */

        /* ============ question requirement start =============  */
        editQuestionData,
        setEditQuestionData,
        reloadQuestionList,
        setReloadQuestionList,
        resetQuestionPopup,
        setResetQuestionPopup,
        /* ============ question requirement end =============  */

        /* ============ survey requirement start =============  */
        reloadSurveyList,
        setReloadSurveyList,
        editSurveyData,
        setEditSurveyData,
        /* ============ survey requirement end =============  */

        /* ============ mail template requirement start =============  */
        reloadMailTemplateList,
        setReloadMailTemplateList,
        editMailTemplateData,
        setEditMailTemplateData,
        viewMailTemplateData,
        setViewMailTemplateData,
        copyMailTemplateId,
        setCopyMailTemplateId,
        searchMailTemplateDate,
        setSearchMailTemplateDate,
        searchMailTemplateName,
        setSearchMailTemplateName,
        searchMailTemplateSubject,
        setSearchMailTemplateSubject,
        searchMailTemplateLabels,
        setSearchMailTemplateLabels,
        searchMailTemplateIsPublic,
        setSearchMailTemplateIsPublic,
        showMailTemplateNameColumn,
        setShowMailTemplateNameColumn,
        showMailTemplateSlugColumn,
        setShowMailTemplateSlugColumn,
        showMailTemplateSubjectColumn,
        setShowMailTemplateSubjectColumn,
        showMailTemplateLabelColumn,
        setShowMailTemplateLabelColumn,
        showMailTemplateDateColumn,
        setShowMailTemplateDateColumn,
        /* ============ mail template requirement end =============  */

        /* ============ message/mail conversation requirement start =============  */
        reloadConversationList,
        setReloadConversationList,
        viewConversationData,
        setViewConversationData,
        searchMailReciever,
        setSearchMailReciever,
        searchMailSender,
        setSearchMailSender,
        searchMailSubject,
        setSearchMailSubject,
        searchMailDate,
        setSearchMailDate,
        searchMailTemplateSelect,
        setSearchMailTemplateSelect,
        searchMailLabelSelect,
        setSearchMailLabelSelect,
        /* ============ message/mail conversation requirement end =============  */

        /* ============== sales order requirement start ============= */
        salesOrderData,
        setSalesOrderData,
        reloadSalesOrderList,
        setReloadSalesOrderList,

        searchSalesOrderDate,
        setSearchSalesOrderDate,
        searchSalesOrderDateType,
        setSearchSalesOrderDateType,
        searchSalesOrderClients,
        setSearchSalesOrderClients,
        searchSalesOrderBudgetStatus,
        setSearchSalesOrderBudgetStatus,
        searchSalesOrderDiscount,
        setSearchSalesOrderDiscount,
        showSalesOrderInvoiceIdColumn,
        setShowSalesOrderInvoiceIdColumn,
        showSalesOrderClientColumn,
        setShowSalesOrderClientColumn,
        showSalesOrderInvoiceDateColumn,
        setShowSalesOrderInvoiceDateColumn,
        showSalesOrderBudgetStatusColumn,
        setShowSalesOrderBudgetStatusColumn,
        showSalesOrderDiscountColumn,
        setShowSalesOrderDiscountColumn,
        showSalesOrderTotalAmountColumn,
        setShowSalesOrderTotalAmountColumn,
        showSalesOrderCreatedDateColumn,
        setShowSalesOrderCreatedDateColumn,
        showSalesOrderPayblePriceColumn,
        setShowSalesOrderPayblePriceColumn,

        //edit sales order item
        editSalesOrderItemData,
        setEditSalesOrderItemData,
        afterEditReload,
        setAfterEditReload,
        clientEmail,
        setclientEmail,
        budgetToSalesOrderId,
        setBudgetToSalesOrderId,
        /* ============== sales order requirement end ============= */

        /* ============== concept requirement start ============= */
        reloadConceptList,
        setReloadConceptList,
        resetAddUpdateConceptPopup,
        setResetAddUpdateConceptPopup,
        editConceptData,
        setEditConceptData,
        /* ============== concept requirement end ============= */

        /* ---------------- feedback response requirement start --------------------- */
        reloadFeedbackResponse,
        setReloadFeedbackResponse,
        /* ---------------- feedback response requirement end --------------------- */

        /* ----------------- reserve cost template requirement start ----------------------- */

        reloadCostList,
        setReloadCostList,
        editCostData,
        setEditCostData,
        searchCostDate,
        setSearchCostDate,
        searchCostEducators,
        setSearchCostEducators,
        searchCostClients,
        setSearchCostClients,
        /* ----------------- reserve cost template requirement end ----------------------- */

        /* ------ schedule material requirement start --------*/
        reloadScheduleMaterial,
        setReloadScheduleMaterial,
        editScheduleMaterialData,
        setEditScheduleMaterialData,
        /* ------ schedule material requirement end --------*/

        /*-------- agenda requirement start --------*/
        agendaWaitning,
        setAgendaWaitning,
        reloadAgenda,
        setReloadAgenda,
        filterAgendaIncludeClientId,
        setfilterAgendaIncludeClientId,
        filterAgendaExcludeClientId,
        setfilterAgendaExcludeClientId,
        filterAgendaEducatorTags,
        setFilterAgendaEducatorTags,
        /*-------- agenda requirement end --------*/

        /*------- RolePriviledge requirement start -------*/
        reloadRolePriviledgeList,
        setReloadRolePriviledgeList,
        rolePriviledgeData,
        setRolePriviledgeData,
        /*------- RolePriviledge requirement end -------*/

        /*------- project requirement start -------*/
        reloadProjectList,
        setreloadProjectList,
        editProjectId,
        seteditProjectId,
        filterDateRange,
        filterTitle,
        filterTags,
        setFilterTitle,
        setFilterDateRange,
        setFilterTags,
        /*------- project requirement end -------*/

        /*------- task requirement end -------*/
        reloadTaskList,
        setreloadTaskList,
        editTaskId,
        seteditTaskId,
        filterTaskDateRange,
        setfilterTaskDateRange,
        filterTaskTitle,
        setfilterTaskTitle,
        filterTaskProjects,
        setfilterTaskProjects,
        filterTaskUsers,
        setfilterTaskUsers,
        filterTaskStatus,
        setfilterTaskStatus,
        filterTaskPriorities,
        setfilterTaskPriorities,
        filterTaskLabels,
        setfilterTaskLabels,
        /*------- task requirement end -------*/

        /*------- employee log requirement end -------*/
        reloadEmployeeLogList,
        setReloadEmployeeLogList,
        filterEmployeeLogDates,
        setfilterEmployeeLogDates,
        filterEmployeeLogUsers,
        setfilterEmployeeLogUsers,
        /*------- employee log requirement end -------*/

        /*------- actionlog requirement start -------*/
        reloadActionLogList,
        setreloadActionLogList,
        filterActionLogDates,
        setfilterActionLogDates,
        /*------- actionlog requirement end -------*/

        /* =============== notification requirement start =================== */
        success,
        setSuccess,
        error,
        setError,
        notificationString,
        setNotificationString,
        /* =============== notification requirement end =================== */

        /* ============== screen height ============================*/
        screenHeight,
        setScreenHeight,
      }}
    >
      {props.children}
    </GlobalProvider.Provider>
  );
};

export default GlobalContext;
