/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import api requiremnts
import * as url from "urlhelpers/UrlHelper";
import { getData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

/* importing email history data */
import emailHistory from "data/EmailHistory.json";

const EmailHistoryPopup = () => {
  const token = localStorage.getItem("token");

  const { t } = useTranslation(); //for translation

  const {
    reservationEmailHistoryData,
    setReservationEmailHistoryData,
    setViewConversationData,
    setViewMail,
    screenHeight,
  } = useContext(GlobalProvider);

  const [mailList, setMailList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);
  const [mailIdOfPerson, setMailIdOfPerson] = useState("");
  const [mailPersonPhone, setMailPersonPhone] = useState("");

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(mailList, initialDataState));

  const onDataStateChange = (event) => {
    console.log("event -->", event);
    setDataState(event.dataState);
    setResult(process(mailList, event.dataState));
  };

  //function for get all mails
  const getAllMails = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_RESERVE_MESSAGE +
        `?token=${token}&reserveid=${reservationEmailHistoryData.reserveId}&receiver=${reservationEmailHistoryData.mailpersonid}&reservation=${reservationEmailHistoryData.reserveEvent}`;

      console.log("Request url of email history=========>", requestUrl);

      const response = await getData(requestUrl);

      console.log("Response of email history=========>", response);

      if (response.status) {
        setMailList(response.data);
        setResult(process(response.data, initialDataState));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  /* -------------- action html start---------------- */
  const actionHtml = ({ dataItem }) => {
    return (
      <td className="kendoCustomColumn overflow-visible action d-flex gap-2 align-items-center">
        {dataItem.tracker == "2" ? (
          <span className="d-block cursor-p material-symbols-outlined text-green icon-md">
            mark_email_read
          </span>
        ) : (
          ""
        )}

        {dataItem.tracker == "1" ? (
          <span className="d-block cursor-p material-symbols-outlined text-grey icon-md">
            mail
          </span>
        ) : (
          ""
        )}

        <span
          className="d-block material-symbols-outlined icon-md icon-fill"
          style={{ color: dataItem.colour }}
        >
          thumb_up
        </span>

        <div className="code">
          <Link
            data-bs-toggle="offcanvas"
            onClick={() => {
              setViewConversationData(dataItem);
              setViewMail(true);
            }}
            to="#EmailDetailsPopup"
            style={{ color: dataItem.colour }}
          >
            <span className="d-block cursor-p material-symbols-outlined icon-md">
              wysiwyg
            </span>
          </Link>
        </div>
      </td>
    );
  };
  /* -------------- action html end---------------- */

  //function for reset
  const resetHandler = () => {
    setReservationEmailHistoryData(null);
  };

  useEffect(() => {
    if (reservationEmailHistoryData) {
      console.log(
        "reservationEmailHistoryData========>",
        reservationEmailHistoryData
      );
      setMailIdOfPerson(reservationEmailHistoryData.mailPersonEmail);
      setMailPersonPhone(reservationEmailHistoryData.mailPersonPhone);
      getAllMails();
    }
  }, [reservationEmailHistoryData]);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="email_history_popup"
      aria-labelledby="historyOfEmailPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h4 className="text-secondary mb-0" id="historyOfEmailPopupLabel">
          {t("History of Email")}{" "}
          <span className="text-primary">{mailIdOfPerson} {' '} {mailPersonPhone}</span>
        </h4>
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <div className="table-responsive">
          <Grid
            style={{ minWidth: "100%", height: screenHeight }}
            data={result}
            // filterable={true}
            sortable={true}
            // groupable={true}
            onDataStateChange={onDataStateChange}
            {...dataState}
            //pageable = {true}
            //total = {products.length}
            reorderable
            // resizable
          >
            <GridColumn field="maildate" title={t("Date")} width="200px" />
            <GridColumn
              field="sendername"
              title={t("Sent From")}
              width="200px"
            />
            <GridColumn field="sendermail" title={t("Email")} width="200px" />
            <GridColumn field="subject" title={t("Subject")} width="200px" />
            <GridColumn
              field="tracker"
              cell={actionHtml}
              title={t("View")}
              width="200px"
            />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default EmailHistoryPopup;
